@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  #root {
    @apply bg-light-main;
    overflow: clip;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  @font-face {
    font-family: LeagueSpartan;
    src: url('./assets/fonts/LeagueSpartan-VariableFont_wght.ttf');
    font-weight: auto;
  }

  @font-face {
    font-family: Montserrat;
    src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf');
    font-weight: auto;
  }
}

@layer utilities {
  .layout {
    @apply max-w-limit sm:w-full md:mx-auto md:w-96;
  }
}

html,
body {
  overscroll-behavior-x: none;
  touch-action: none;
}

*:not(input):not([contentEditable='true']) {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgb(170, 170, 170);
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
  pointer-events: all;
}

img {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.App {
  background-color: #282c34;
  text-align: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-evenly;
}

.app-floating-left-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding-top: 30px; */
  padding: 0;
  justify-content: space-between;
  pointer-events: none;
  z-index: 10000;
}

.app-floating-right-container {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  padding: 0;
  align-items: flex-end;
  /* padding-top: 30px; */
  justify-content: flex-start;
  gap: 16px;
  z-index: 10000;
}

.app-playback-container {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: clip;
}

.app-youtube-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: black;
}

.App-logo {
  /*height: 40vmin;*/
  /*max-width: calc(100% - 50px);;*/
  width: calc(100% - 50px);
  margin: 50px 50px 0 50px;
}

.App-right-panel-user {
  color: white;
  background-color: rgba(221, 61, 78, 0.49);
  position: fixed;
  bottom: 0;
  right: 0;
}

.App-header-user {
  position: fixed;
  right: 0;
  top: 0;
  margin: 1vmin;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.search-bar {
  height: 35px;
  box-sizing: border-box;
  max-width: 500px;
  width: calc(100% - 50px);
  border-radius: 4px;
  border: none;
  padding: 8px;
}

.app-playback-example-or {
  font-size: 12px;
  font-weight: 800;
}

.app-playback-example-link {
  color: #000 !important;
  font-family: LeagueSpartan, sans-serif;
  font-size: 14px;
  margin: 16px;
  font-weight: 550;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  padding: 12px 24px;
}

.app-playback-go-button {
  color: #e8e8e8 !important;
  font-family: LeagueSpartan, sans-serif;
  font-size: 14px;
  font-weight: 700;
  background-color: #d63e4f;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0 24px;
}

.app-playback-go-button:hover {
  filter: brightness(120%);
}

.app-playback-example-broadcast-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
  align-items: center;
}

.app-playback-example-broadcast-title {
  position: relative;
  font-family: LeagueSpartan, sans-serif;
  text-align: center;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 20px;
  align-items: center;
}

.app-playback-example-broadcast-title:before {
  position: relative;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: #dd3d4e;
  border-radius: 50%;
}

.app-playback-example-broadcast-list {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.app-playback-example-broadcast-list > * {
  flex-shrink: 0;
  flex-grow: 0;
}

[contentEditable='true']:empty:not(:focus)::before,
[data-dblclick-editable='true']:empty:not(:focus)::before,
[data-disabled-editable='true']:empty:not(:focus)::before {
  content: attr(data-text);
  pointer-events: none;
}

.bg-navbar-bottom-gradient {
  background: linear-gradient(
    124.54deg,
    rgba(10, 31, 85, 0.4) 0%,
    rgba(16, 44, 115, 0.4) 27.66%,
    rgba(12, 26, 76, 0.4) 70.02%
  );
}

b {
  font-weight: 600;
}

/** Scroll bar styling **/
/* Firefox */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  --scrollbar-width: 8px;
  scrollbar-width: thin; /* can also be normal, or none, to not render scrollbar */
  scrollbar-color: transparent transparent; /* foreground background */
}

/* Webkit / Blink */
::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-thumb {
  background-color: currentColor;
  border-radius: 9999px; /* always round */
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  display: none;
}
@variants responsive {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    --scrollbar-width: none;
    scrollbar-color: transparent transparent !important;
  }

  .no-scrollbar-y::-webkit-scrollbar {
    width: 0 !important; /* Set width to 0 to hide scrollbar */
    height: 0 !important; /* Set height to 0 to hide scrollbar */
  }

  /* Hide Y-axis scrollbar for IE, Edge, and Firefox */
  .no-scrollbar-y {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
