@font-face {
  font-family: LeagueSpartan;
  src: url('./assets/fonts/LeagueSpartan-VariableFont_wght.ttf');
}
@font-face {
  font-family: Montserrat;
  src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf');
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, Montserrat, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.text-3d {
  text-shadow:
    1px 1px 0 #000,
    2px 2px 0 #000,
    3px 3px 0 #000,
    4px 4px 0 #000,
    5px 5px 0 #000,
    6px 6px 0 #000,
    7px 7px 0 #000,
    8px 8px 0 #000,
    9px 9px 0 #000,
    10px 10px 0 #000;
  color: #fff; /* White text color */
}

.chakra-popover__popper {
  z-index: 10;
}
